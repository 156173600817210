export const GrillSpecs = (props: any) => {

  const splitCaps = (word: string) => {
    return word.split(/(?=[A-Z])/).join(" ");
  };

  return (<>
    {Object.keys(props.specs).map((key: any, idx: any) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            borderTop: `1px solid #444444`
          }}
          key={key + idx}
        >
          <div
            style={{
              flex: 1,
              maxWidth: '11rem',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              fontWeight: 'bold',
              padding: '.5rem .5rem .5rem 1rem',
              textTransform: 'capitalize'
            }}
          >
            {splitCaps(key)}
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              textAlign: 'right',
              padding: '.5rem 1rem .5rem .5rem'
            }}
          >
            {props.specs[key]}
          </div>
        </div>
      );
    })}
  </>);
};

export default GrillSpecs;
