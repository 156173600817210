import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
export const HomeTile = (props: any) => {

  return (
    <Link
      to={props.path}
      style={{ textDecoration: 'none', flex: 1, maxWidth: '14rem' }}
    >
      <div
        style={{
          backgroundColor: props.color,
          display: 'flex',
          flexDirection: 'column',
          color: 'white',
          borderRadius: '1rem',
          margin: '.5rem',
          minHeight: '12rem',
          padding: '1rem',
          minWidth: '8rem',
        }}
      >
        <div
          style={{
            borderRadius: '1rem',
            background: 'rgba(255,255,255,0.2)',
            height: '1rem',
            width: '100%', 
          }}
        >
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
        <Box component={props.icon} style={{ fontSize: '4rem' }} />
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{ fontFamily: 'Roboto Slab', fontWeight: '600' }}
        >
          {props.label}
        </Typography>
        </div>
      </div>
    </Link>
  );
};

export default HomeTile;
