import SingleImage from '../assets/single_smoked_grill.png';
import DoubleImage from '../assets/double_smoked_grill.png';
import JumboImage from '../assets/jumbo_smoked_grill.png';

export const grills = [
  {
    id: '0',
    image: SingleImage,
    name: 'Single Smoked',
    rating: 5,
    reviews: 2379,
    specs: {
      retailPrice: 900.00,
      cookingSpace: 450,
      weight: 75,
      capacity: '~5 chickens, ~4 racks of ribs or ~15 burgers, ~30 hotdogs',
      batteryLife: 30,
      batteryChargeTime: 2,
      temperatureRange: '160 to 450',
    }
  },
  {
    id: '1',
    name: 'Double Smoked',
    image: DoubleImage,
    rating: 5,
    reviews: 1154,
    specs: {
      retailPrice: 1500.00,
      cookingSpace: 900,
      weight: 140,
      capacity: '~11 chickens, ~8racks of ribs or ~30 burgers, ~60 hotdogs',
      batteryLife: 60,
      batteryChargeTime: 2,
      temperatureRange: '160 to 500',
    }
  },
  {
    id: '2',
    name: 'Jumbo Smoked',
    image: JumboImage,
    rating: 5,
    reviews: 368,
    specs: {
      retailPrice: 3200.00,
      cookingSpace: 1400,
      weight: 350,
      capacity: '~15 chickens, ~20 racks of ribs or ~75 burgers, ~150 hotdogs',
      batteryLife: 150,
      batteryChargeTime: 2,
      temperatureRange: '160 to 700',
    }
  },
];
export default grills;
