import { useEffect, useState } from 'react';

export const useUjet = () => {

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [ready, setReady] = useState(false);
  const [started, setStarted] = useState(false);
  const [ujet, setUjet] = useState<any>();
  const [user, setUser] = useState<any>();

  // GET USER FROM LOCAL STORAGE
  const getUser = () => {

    const defaultUser = JSON.stringify({
      identifier: process.env.REACT_APP_JWT_IDENTIFIER,
      name: process.env.REACT_APP_JWT_NAME,
      email: process.env.REACT_APP_JWT_EMAIL,
      phone: process.env.REACT_APP_JWT_PHONE
    });

    if (typeof window === "undefined") {
      return defaultUser;
    };
    const userData = window.localStorage.getItem('user');
    if (userData !== "undefined") {
      return userData;
    }
    return defaultUser;
  };

  // LOAD & APPEND UJET API
  useEffect(() => {
    let ujetScript = document.createElement('script');
    ujetScript.src = 'https://websdk.ujet.co/v2/loader.js';
    ujetScript.addEventListener('load', () => {
      setLoaded(true);
    });
    document.body.appendChild(ujetScript);

  },[loaded]);

  // CONFIGURE AND RUN UJET WIDGET
  useEffect(() => {

    if (!loaded) return;

    const tokenPath = process.env.REACT_APP_UJET_TOKEN_PATH;
    const host = process.env.REACT_APP_UJET_HOST;
    const id = process.env.REACT_APP_UJET_ID;
    const translation = {
      en: {
        ujet_message_chat_start: "",
        ujet_greeting: "",
        ujet_message_queue: "",
        ujet_channel_chat: "",
      },
    };

    const ujetParams = {
      companyId: id,
      translation: translation,
      host: host,
      style: {
        '--primary-color': '#ff5000',
      },
      launcher: false
    };

    const newUjet = new (window as any).UJET(ujetParams);

    const jwtRequest = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        payload: getUser()
      })
    };
    console.log(`UJET JWT REQUEST`);
    console.log(JSON.stringify(jwtRequest, null, 2));

    async function postData(url = '', data = {}) {
      await fetch(url, data)
        .then(res => res.json())
        .then(result => {
          newUjet.authenticate(result);
        });
    }

    newUjet.on('created', () => {
      postData(tokenPath, jwtRequest);
      console.log(`UJET 'created' STATUS: ${newUjet.status}`);
    });

    newUjet.on('ready', () => {
      console.log(`UJET 'ready' STATUS: ${newUjet.status}`);
      setReady(true);
    });

    newUjet.on('loading', () => {
      console.log(`UJET 'loading' STATUS: ${newUjet.status}`);
    });

    newUjet.on('open', () => {
      console.log(`UJET 'open' STATUS: ${newUjet.status}`);
    });

    newUjet.on('close', () => {
      console.log(`UJET 'close' STATUS: ${newUjet.status}`);
    });

    setUjet(newUjet);

  },[loaded]);

  const launch = () => {
    if (!started) {
      ujet.start({ menuKey: 'CDW Grills Virtual Agent'});
      setStarted(true);
    }
    console.log(`LAUNCHUJET INIT STATUS: ${ujet.status}`);
    console.log(`LAUNCHUJET LOADED: ${loaded}`);
    if (loaded) {
      if (ujet.status === 'open') {
        console.log(`LAUNCHUJET OPEN STATUS: ${ujet.status}`);
        ujet.close();
      } else {
        console.log(`LAUNCHUJET CLOSE STATUS: ${ujet.status}`);
        ujet.open();
      };
    };
  };

  return {
    launch,
    loaded,
    ready,
  }

};

export default useUjet;
