import { LoadingScreen } from '../../components';

export const CommunityPage = () => {

  return (
    <LoadingScreen label="Community" /> 
  );

};
export default CommunityPage;
