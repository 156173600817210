import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { menu } from '../../constants';
import { usePageName } from '../../hooks';
import { theme } from '../../theme/theme';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export const AppLayout = (props: any) => {

  const location = useLocation();

  const [isDetailPage, setIsDetailPage] = useState(false);

  const navigate = useNavigate();

  const pageName = usePageName();

  const inLoc = (path: string) => {
    return location.pathname.includes(path);
  };

  useEffect(() => {
    // The trailing '/' on the includes string identifies the path as having an :id
    if (inLoc('/buy') || inLoc('/grills/') || inLoc('/order-history')) {
      setIsDetailPage(true);
    } else {
      setIsDetailPage(false);
    };
  },[location]);


  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {menu.map((item) => (
          <NavLink
            to={item.path || '/'}
            key={item.label}
            style={
              ({isActive}) => (isActive ?
              {
                color: '#444444',
                fontWeight: 'bold',
                textDecoration: 'none',
              } :
              {
                color: '#444444',
                fontWeight: 'normal',
                textDecoration: 'none',
              })
            }
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Box component={item.icon} />
                </ListItemIcon>
                {item.label}
              </ListItemButton>
            </ListItem>
          </NavLink>
        ))}
      </List>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <div style={{height: '100vh', backgroundColor: theme.palette.primary.main }}>
        <Drawer
          anchor="left"
          open={state['left']}
          onClose={toggleDrawer('left', false)}
        >
          {list('left')}
        </Drawer>
        {pageName !== 'Logout' && (
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" elevation={0}>
              <Toolbar>
                {isDetailPage && (
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => navigate(-1)}
                   >
                    <ArrowBackIcon />
                  </IconButton>
                )}
                {!isDetailPage && (
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer('left', true)}
                   >
                    <MenuIcon />
                  </IconButton>
                )}

                <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', fontFamily: 'Roboto Slab', fontWeight: 900 }}>
                  {pageName}
                </Typography>
                <IconButton
                  size="large"
                  edge="end"
                  color="inherit"
                  aria-label="cart"
                  onClick={() => navigate("/cart")}
                 >
                  <ShoppingCartIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          </Box>
        )}
        {props.children}
      </div>
    </ThemeProvider>
  );
}
export default AppLayout;
