import { LoadingScreen } from '../../components';

export const RecipesPage = () => {

  return (
    <LoadingScreen label="Recipes" /> 
  );

};
export default RecipesPage;
