import { LoadingScreen } from '../../components';

export const CartPage = () => {

  return (
    <LoadingScreen label="Shopping Cart" /> 
  );

};
export default CartPage;
