import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { menu } from '../constants';

export const usePageName = () => {

  const location = useLocation();

  const defaultPageName = 'Grills';

  const[pageName, setPageName] = useState("");

  useEffect(() => {

    const pathFragments = location.pathname.split('/');

    const frag = `/${pathFragments[1]}`;

    const pageObject = menu.find(({path}) => path === frag);

    if (pageObject && pageObject.label) {
      setPageName(pageObject.label);
    } else {
      setPageName(defaultPageName);
    }
  },[location, menu]);
  return pageName;

}; 

export default usePageName;
