import { OrderHistoryGrid } from '../../components/';
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';

const rows: GridRowsProp = [
  { id: 1, col1: 'Hello', col2: 'World' },
  { id: 2, col1: 'Hola', col2: 'World' },
  { id: 3, col1: 'Goodbye', col2: 'World' },
];

const cols: GridColDef[] = [
  { field: 'col1', headerName: 'Column 1', width: 150 },
  { field: 'col2', headerName: 'Column 2', width: 150 },
];

export const OrderHistoryPage = () => {


  return (
    <div style={{height: 300, background: '#efefef', margin: '.25rem' }}>
      <OrderHistoryGrid rows={rows} columns={cols} />
    </div>
  );

};
export default OrderHistoryPage;
