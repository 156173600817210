import { LoadingScreen } from '../../components';

export const ChatPage = () => {

  return (
    <LoadingScreen label="Chat" /> 
  );

};
export default ChatPage;
