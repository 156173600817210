import { useEffect, useState } from 'react';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { AppFab, GrillTile } from '../../components';
import { grills } from '../../constants';
import { useUjet } from '../../hooks';

export const GrillsPage = () => {
  const { launch, loaded, ready } = useUjet();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const openUjet = () => {
    console.log(`Loaded: ${loaded}`);
    handleOpen();
    if (loaded) {
      launch();
    }
  };

  useEffect(() => {
    if(ready && open) {
      handleClose();
    };
  },[ready, open]);
  return (
    <div style={{
      height: 'calc(100vh - 4rem)',
      backgroundColor: '#2f2e2e',
      borderRadius: '1rem 1rem 0 0',
      padding: '1rem .85rem',
      overflow: 'auto',
    }}>
      <AppFab onClick={openUjet}>
        <HeadsetMicIcon sx={{ fontSize:  '2rem' }} />
        CHAT
      </AppFab>
      {grills.map((grill, idx) => (
        <GrillTile
          key={idx}
          id={grill.id}
          image={grill.image}
          name={grill.name}
          price={grill.specs.retailPrice}
        />        
      ))}
      <div style={{ height: '10rem' }} />
    </div>
  );
};
export default GrillsPage;
