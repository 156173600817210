import { DataGrid } from '@mui/x-data-grid';


export const OrderHistoryGrid = (props: any) => {

  return (
    <DataGrid rows={props.rows} columns={props.columns} hideFooter={true} />
  );

};
export default OrderHistoryGrid;
