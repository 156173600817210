import { LoadingScreen } from '../../components';

export const LessonsPage = () => {

  return (
    <LoadingScreen label="Lessons" /> 
  );

};
export default LessonsPage;
