import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { AppFab, AppButton, GrillImage, GrillSpecs, StarRate } from '../../components';
import { grills } from '../../constants';
import { useUjet } from '../../hooks';

export const GrillDetailPage = () => {

  const params = useParams();

  const [grillDetails, setGrillDetails] = useState<any>({});

  const [specs, setSpecs] = useState<any>({});

  const theme = useTheme();

  const { launch, loaded, ready } = useUjet();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const openUjet = () => {
    console.log(`Loaded: ${loaded}`);
    handleOpen();
    if (loaded) {
      launch();
    }
  };

  useEffect(() => {

    const grillObject = grills.find(({id}) => id == params.id);
    grillObject ? setGrillDetails(grillObject) : setGrillDetails({});
    grillObject?.specs ? setSpecs(grillObject.specs) : setSpecs({});

    if(ready && open) {
      handleClose();
    };

  },[params.id, grills, ready, open]);

  return (
    <div style={{
        height: 'calc(100vh - 3rem)',
        background: theme.palette.grey[900],
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '1rem 1rem 0 0',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <AppFab onClick={openUjet}>
        <HeadsetMicIcon sx={{ fontSize:  '2rem' }} />
        CHAT
      </AppFab>
      <GrillImage image={grillDetails.image} />

      <Typography variant="h5" sx={{ fontFamily: 'Roboto Slab', fontWeight: '600', marginTop: '1rem' }}>
        {grillDetails.name}
      </Typography>

      <StarRate reviews={grillDetails.reviews} />

      <GrillSpecs specs={specs} />

      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '2rem' }}>
        <div style={{ width: '2rem' }} />
        <AppButton
          link={`/buy/${params.id}`}
          variant="outlined"
          color="secondary"
          size="large"
          startIcon={<AddShoppingCartIcon />}
        >
          Buy Now
        </AppButton>
        <div style={{ width: '2rem' }} />
      </div>
    </div>
  );

};
export default GrillDetailPage;
