export enum ROUTES {
  ACCESSORIES = '/accessories',
  ADMIN = '/admin',
  BASE = '/',
  BUY = '/buy',
  CART = '/cart',
  CHAT = '/chat',
  COMMUNITY = '/community',
  GRILLS = '/grills',
  HOME = '/home',
  ORDER_HISTORY= '/order-history',
  LESSONS = '/lessons',
  LOGIN = '/login',
  RECIPES= '/recipes',
}
export default ROUTES;
