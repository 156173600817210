import React from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import { ROUTES } from './constants';
import { AppLayout } from './components';
import {
  AccessoriesPage,
  AdminPage,
  BuyPage,
  CartPage,
  ChatPage,
  CommunityPage,
  GrillsPage,
  GrillDetailPage,
  HomePage,
  LessonsPage,
  LoginPage,
  OrderDetailPage,
  OrderHistoryPage,
  RecipesPage
} from './pages';

export const App = () => {
  return (
    <BrowserRouter>
      <AppLayout>
        <Routes>
          <Route
            path={ROUTES.BASE}
            element={<Navigate to={ROUTES.LOGIN} replace />}
          />
          <Route
            path={ROUTES.ACCESSORIES}
            element={<AccessoriesPage />}
          />
          <Route
            path={ROUTES.ADMIN}
            element={<AdminPage />}
          />
          <Route
            path={`${ROUTES.BUY}/:id`}
            element={<BuyPage />}
          />
          <Route
            path={ROUTES.CART}
            element={<CartPage />}
          />
          <Route
            path={ROUTES.CHAT}
            element={<ChatPage />}
          />
          <Route
            path={ROUTES.COMMUNITY}
            element={<CommunityPage />}
          />
          <Route
            path={`${ROUTES.GRILLS}/:id`}
            element={<GrillDetailPage />}
          />
          <Route
            path={ROUTES.GRILLS}
            element={<GrillsPage />}
          />
          <Route
            path={ROUTES.HOME}
            element={<HomePage />}
          />
          <Route
            path={`${ROUTES.ORDER_HISTORY}/:id`}
            element={<OrderDetailPage />}
          />
          <Route
            path={ROUTES.ORDER_HISTORY}
            element={<OrderHistoryPage />}
          />
          <Route
            path={ROUTES.LESSONS}
            element={<LessonsPage />}
          />
          <Route
            path={ROUTES.LOGIN}
            element={<LoginPage />}
          />
          <Route
            path={ROUTES.RECIPES}
            element={<RecipesPage />}
          />
        </Routes>
      </AppLayout>
    </BrowserRouter>
  );
}

export default App;
