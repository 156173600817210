import { LoadingScreen } from '../../components';

export const AccessoriesPage = () => {

  return (
    <LoadingScreen label="Accessories" /> 
  );

};
export default AccessoriesPage;
