import { createTheme } from '@mui/material/styles';
import { red, orange } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: red[900],
      contrastText: '#fff',
    },
    secondary: {
      main: orange[800],
      contrastText: '#fff',
    },
  }
});

export default theme;
