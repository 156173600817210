import GroupsIcon from '@mui/icons-material/Groups';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { ROUTES } from './routes.enum';

export const menu = [
  {
    icon: HomeIcon,
    label: 'Home',
    name: 'Home',
    path: ROUTES.HOME,
    isActive: true,
  },
  {
    icon: HistoryIcon,
    label: 'Buy',
    name: 'Buy',
    path: ROUTES.BUY,
    isActive: true,
  },
  {
    icon: ShoppingCartIcon,
    label: 'Shopping Cart',
    name: 'Cart',
    path: ROUTES.CART,
    isActive: true,
  },
  {
    icon: OutdoorGrillIcon,
    label: 'Grills',
    name: 'Grills',
    path: ROUTES.GRILLS,
    isActive: true,
  },
  {
    icon: RestaurantIcon,
    label: 'Accessories',
    name: 'Accessories',
    path: ROUTES.ACCESSORIES,
    isActive: true,
  },
  {
    icon: MenuBookIcon,
    label: 'Recipes',
    name: 'Recipes',
    path: ROUTES.RECIPES,
    isActive: true,
  },
  {
    icon: SmartDisplayIcon,
    label: 'Lessons',
    name: 'Lessons',
    path: ROUTES.LESSONS,
    isActive: true,
  },
  {
    icon: GroupsIcon,
    label: 'Community',
    name: 'Community',
    path: ROUTES.COMMUNITY,
    isActive: true,
  },
  {
    icon: HeadsetMicIcon,
    label: 'Chat',
    name: 'Chat',
    path: ROUTES.CHAT,
    isActive: true,
  },
  {
    icon: HistoryIcon,
    label: 'Order History',
    name: 'Order History',
    path: ROUTES.ORDER_HISTORY,
    isActive: true,
  },
  {
    icon: LogoutIcon,
    label: 'Logout',
    name: 'Login',
    path: ROUTES.LOGIN,
    isActive: true,
  },
  {
    icon: SettingsIcon,
    label: 'Admin',
    name: 'Admin',
    path: ROUTES.ADMIN,
    isActive: true,
  },
];
export default menu;
