import GroupsIcon from '@mui/icons-material/Groups';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { ROUTES } from './routes.enum';

export const tiles = [
  {
    id: 0,
    label: 'Grills',
    icon: OutdoorGrillIcon,
    path: ROUTES.GRILLS,
    color: 'red',
  },
  {
    id: 1,
    label: 'Accessories',
    icon: RestaurantIcon,
    path: ROUTES.ACCESSORIES,
    color: 'red',
  },
  {
    id: 2,
    label: 'Recipes',
    icon: MenuBookIcon,
    path: ROUTES.RECIPES,
    color: 'red',
  },
  {
    id: 3,
    label: 'Lessons',
    icon: SmartDisplayIcon,
    path: ROUTES.LESSONS,
    color: 'red',
  },
  {
    id: 4,
    label: 'Community',
    icon: GroupsIcon,
    path: ROUTES.COMMUNITY,
    color: 'red',
  },
];
// EXCLUDED
/*
  {
    id: 5,
    label: 'Chat',
    icon: HeadsetMicIcon,
    path: ROUTES.CHAT,
    color: 'orange',
  },
*/
export default tiles;
