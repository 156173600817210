import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { tiles } from '../../constants';
import { AppFab, HomeTile } from '../../components';
import { useUjet } from '../../hooks';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem',
};

export const HomePage = () => {

  const { launch, loaded, ready } = useUjet();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const openUjet = () => {
    console.log(`Loaded: ${loaded}`);
    handleOpen();
    if (loaded) {
      launch();
    }
  };

  useEffect(() => {
    if(ready && open) {
      handleClose();
    };
  },[ready, open]);

  return (
    <div style={{
      height: 'calc(100vh - 4rem)',
      backgroundColor: '#2f2e2e',
      borderRadius: '1rem 1rem 0 0',
      padding: '.5rem',
      overflow: 'auto',
    }}>
      <AppFab onClick={openUjet}>
        <HeadsetMicIcon sx={{ fontSize:  '2rem' }} />
        CHAT
      </AppFab>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Chat Session"
        aria-describedby="Chat Session Loading"
      >
        <Box sx={modalStyle}>
          <CircularProgress />
          Loading Chat
        </Box>
      </Modal>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}>
        {tiles.map((tile, id) => (
          <HomeTile
            key={id}
            path={tile.path}
            label={tile.label}
            color={tile.color == 'red' ? theme.palette.primary.main : theme.palette.secondary.main }
            icon={tile.icon}
          />
        ))}
      </div>
    </div>
  );
};
export default HomePage;
