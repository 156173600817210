import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Link } from 'react-router-dom';

export const GrillTile = (props: any) => {
  return (
    <div
      style={{
        background: '#222222',
        backgroundImage: `url(${props.image})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        borderRadius: '1rem',
        marginBottom: '1rem',
        height: '15rem',
      }}
     >
      <div
        style={{
          background: 'rgba(0,0,0,0.8)',
          display: 'flex',
          flexDirection: 'row',
          borderRadius: '0 0 1rem 1rem',
          padding: '1rem',
          height: '3rem',
        }}
      >
        <Link
          to={`/grills/${props.id}`}
          style={{
            color: 'white',
            textDecoration: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            flex: 1,
          }}
        >
          <Typography variant="h5" sx={{ fontFamily: 'Roboto Slab', fontWeight: '600' }}>{props.name}</Typography>
          <span>${props.price}.00</span>
        </Link>
        <Link
          to={`/grills/${props.id}`}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconButton sx={{ mr: 2, color: "white" }}>
            <VisibilityIcon />
          </IconButton>
        </Link>
        <Link to={`/buy/${props.id}`}>
          <IconButton>
            <AddShoppingCartIcon sx={{ color: "white" }} />
          </IconButton>
        </Link>
      </div>
    </div>
  );
};

export default GrillTile;
