export const GrillImage = (props: any) => {
  return (
    <div
      style={{
        width: '100%',
        background: '#111111',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <div
        style={{
          width: '100%',
          maxWidth: '30rem',
          height: '16rem',
          background: '#aaaaaa',
          backgroundImage: `url(${props.image})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      />
    </div>
  );  
};

export default GrillImage;
