import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { AppInput } from '../../components';
import { grills } from '../../constants';

export const BuyPage = (props: any) => {

  const params = useParams();
  const theme = useTheme();
  const [grillDetails, setGrillDetails] = useState<any>({});
  const [specs, setSpecs] = useState<any>({});
  const [phone, setPhone] = useState<any>("");
  const [name, setName] = useState<any>("");
  const [orderSuccess, setOrderSuccess] = useState<any>(false);

  const buyGrill = (event: any) => {
    console.log(`[BUY] Name: ${name}, Phone: ${phone}, Grill: ${grillDetails.name}, Condition: new`);
    if(name && phone && grillDetails.name) {
      const dbPath = `${process.env.REACT_APP_DB_PATH}/order/new`;
      const dbRequest = {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': dbPath, 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          phone: phone,
          name: name,
          grill: grillDetails.name,
          condition: 'new',
        })
      };
      fetch(dbPath, dbRequest)
        .then((response) => response.json())
        .then((json) => {
          console.log(`>DB BUY RESPONSE: ${JSON.stringify(json)}`);
          if (json.status === 'success') {
            setOrderSuccess(true);
          };
        });
    } else {
      console.log(`buyGrill() ERROR`);
    };
  };


  useEffect(() => {
    const grillObject = grills.find(({id}) => id == params.id);
    grillObject ? setGrillDetails(grillObject) : setGrillDetails({});
    grillObject?.specs ? setSpecs(grillObject.specs) : setSpecs({});
  },[params.id, grills]);

  return (
    <div style={{
        height: 'calc(100vh - 3rem)',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '1rem 1rem 0 0',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <h4>Purchasing {grillDetails.name}</h4>
      <AppInput
        id="name"
        label="Name"
        variant="outlined"
        size="small"
        width="300px"
        value={name}
        onChange={(name: any) => setName(name.target.value)}
        
      />
      <div style={{ height: '1rem' }} />
      <AppInput
        id="phone"
        label="Phone"
        variant="outlined"
        size="small"
        width="300px"
        value={phone}
        onChange={(phone: any) => setPhone(phone.target.value)}
      />
      <div style={{ height: '1rem' }} />
      <Button
        disableElevation
        endIcon={<AddShoppingCartIcon />}
        color="secondary"
        variant="contained"
        size="small"
        sx={{
          borderRadius: '50px',
          width: '300px',
        }}
        onClick={buyGrill}
      >
        <span style={{ marginTop: '.15rem' }}>
        Buy Now
        </span>
      </Button>
      {orderSuccess && (
        <h2>Success!</h2>
      )}
    </div>
  );
};
export default BuyPage;
