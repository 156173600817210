import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { AppButton, AppInput } from '../../components';
import logo from '../../assets/cdw_grills_logo.svg';
import { useNavigate } from 'react-router-dom';

export const LoginPage = () => {

  const [name, setName] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [phone, setPhone] = useState<any>("");

  const theme = useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.localStorage.removeItem('user');
    };
  },[]);

  const handleLogin = () => {
    if (typeof window === "undefined") {
      return false;
    };
    const userState = JSON.stringify({
      identifier: email,
      name: name,
      email: email,
      phone: phone
    });
    window.localStorage.setItem('user', userState);
    navigate('/home');
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        height: '100vh',
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <img
        style={{ width: '16rem', paddingBottom: '4rem' }}
        src={logo}
        alt="CDW Grills"
      />
      <AppInput
        id="name"
        label="Name"
        variant="outlined"
        type="text"
        size="small"
        width="300px"
        value={name}
        onChange={(name: any) => setName(name.target.value)}
      />
      <AppInput
        id="email"
        label="Email"
        variant="outlined"
        type="email"
        size="small"
        width="300px"
        value={email}
        onChange={(email: any) => setEmail(email.target.value)}
      />
      <AppInput
        id="phone"
        label="Phone"
        variant="outlined"
        type="tel"
        size="small"
        width="300px"
        value={phone}
        onChange={(phone: any) => setPhone(phone.target.value)}
      />
      <Button
        disableElevation
        color="secondary"
        variant="contained"
        size="small"
        sx={{
          borderRadius: '50px',
          width: '300px',
        }}
        onClick={handleLogin}
      >
        <span style={{ marginTop: '.15rem' }}>
        Login
        </span>
      </Button>
      <Link to="/home" style={{ textDecoration: 'none', color: 'white' }}>Shop as Guest</Link>
    </Stack>
  );

};
export default LoginPage;
