export const orderHistory = [
  {
    id: '0',
    name: 'Customer One',
    address1: '123 Fourth St.',
    address2: 'Apt. 1',
    city: 'Portland',
    state: 'OR',
    zip: '97101',
    model: 0
  },
  {
    id: '1',
    name: 'Customer Two',
    address1: '456 Seventh St.',
    address2: 'Apt. 2',
    city: 'Tacoma',
    state: 'WA',
    zip: '98404',
    model: 1
  },
  {
    id: '2',
    name: 'Customer Three',
    address1: '789 Tenth St.',
    address2: 'Apt. 3',
    city: 'New York',
    state: 'NY',
    zip: '10001',
    model: 2
  },
];
export default orderHistory;
