import { useNavigate } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';

export const AppFab = (props: any) => {

  const navigate = useNavigate();

  return (
    <Fab
      onClick={props.onClick}
      color="secondary"
      size="large"
      sx={{
        position: 'absolute',
        bottom: '.5rem',
        right: '.5rem',
        borderRadius: '1rem',
        width: '5rem',
        height: '5rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {props.children}
    </Fab>
  );
};

export default AppFab;
