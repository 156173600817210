import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

export const LoadingScreen = (props: any) => {

  const navigate = useNavigate();

  return (
    <div
      style={{
        height: 'calc(100vh - 3rem)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#2f2e2e',
        borderRadius: '1rem 1rem 0 0',
        color: 'white',
    }}>
      <CircularProgress color="warning" />
      <div style={{ opacity: 0.5, padding: '1rem 0 2rem 0' }}>
        Loading {props.label}
      </div>
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        sx={{ borderRadius: '50px' }}
        onClick={() => navigate(-1)}
      >
        Go Back
      </Button>
    </div>
  );
};

export default LoadingScreen;
