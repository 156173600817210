import styled from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

export const AppInput = (props: any) => {

  return (
    <TextField
      onChange={props.onChange}
      id={props.id}
      label={props.label}
      variant={props.variant}
      type={props.type}
      size={props.size}
      sx={{
        '& input': {
          color: 'white',
        },
        '& label': {
          color: 'white',
        },
        '& label.Mui-focused': {
          color: 'white',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'transparent',
          },
          '&:hover fieldset': {
            borderColor: 'orange',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'white',
            backgroundColor: 'rgba(0,0,0,0)',
          },
        },
        '& fieldset': {
          borderRadius: '50px',
          backgroundColor: 'rgba(0,0,0,0.15)',
        },
        width: props.width,
        
      }}
    />
  );

};

export default AppInput;
