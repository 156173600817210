import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

export const AppButton = (props: any) => {
  return (
    <Link to={props.link}>
      <Button
        disableElevation
        endIcon={props.endIcon}
        startIcon={props.startIcon}
        color={props.color}
        variant={props.variant}
        size={props.size}
        sx={{
          borderRadius: '50px',
          width: props.width
        }}
      >
        {props.children}
      </Button>
    </Link>
  );

};

export default AppButton;
