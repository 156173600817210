import StarRateIcon from '@mui/icons-material/StarRate';

export const StarRate = (props: any) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: '.5rem',
        }}
      >
        {[0,1,2,3,4].map((k) => {
          return (<StarRateIcon color="warning" key={k} />)
        })}
      </div>
      {props.reviews && (
        <div
          style={{
            padding: '.5rem 0 1rem 0',
            color: '#03a9f4',
            fontSize: '.85rem',
          }}
        >
          {props.reviews} Reviews
        </div>
      )}
    </>
  );
};

export default StarRate;
