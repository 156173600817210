import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { AppInput } from '../../components';

export const AdminPage = (props: any) => {

  const params = useParams();
  const [phone, setPhone] = useState<any>("");
  const [orderSuccess, setOrderSuccess] = useState<any>(false);

  const destroyDB = (event: any) => {
    const dbPath = `${process.env.REACT_APP_DB_PATH}/destroy`;
    const dbRequest = {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': dbPath, 
        'Content-Type': 'application/json'
      }
    };
    fetch(dbPath, dbRequest)
      .then((response) => response.json())
      .then((json) => {
        console.log(`>DB DESTROY RESPONSE: ${JSON.stringify(json)}`);
        if (json.status === 'success') {
          setOrderSuccess(true);
        };
      });
  };

  const breakGrill = (event: any) => {
    console.log(`[BREAK] Phone: ${phone}`);
    if (phone) {
      const dbPath = `${process.env.REACT_APP_DB_PATH}/order/break`;
      const dbRequest = {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': dbPath, 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ phone: phone })
      };
      fetch(dbPath, dbRequest)
        .then((response) => response.json())
        .then((json) => {
          console.log(`>DB BREAK RESPONSE: ${JSON.stringify(json)}`);
          if (json.status === 'success') {
            setOrderSuccess(true);
          };
        });
    } else {
      console.log(`breakGrill() ERROR`);
    };
  };

  const repairGrill = (event: any) => {
    console.log(`[REPAIR] Phone: ${phone}`);
    if (phone) {
      const dbPath = `${process.env.REACT_APP_DB_PATH}/order/repair`;
      const dbRequest = {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': dbPath, 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ phone: phone })
      };
      fetch(dbPath, dbRequest)
        .then((response) => response.json())
        .then((json) => {
          console.log(`>DB REPAIR RESPONSE: ${JSON.stringify(json)}`);
          if (json.status === 'success') {
            setOrderSuccess(true);
          };
        });
    } else {
      console.log(`breakGrill() ERROR`);
    };
  };

  return (
    <div style={{
        height: 'calc(100vh - 3rem)',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '1rem 1rem 0 0',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <div style={{ height: '1rem' }} />
      <AppInput
        id="phone"
        label="Phone"
        variant="outlined"
        size="small"
        width="300px"
        value={phone}
        onChange={(phone: any) => setPhone(phone.target.value)}
      />
      <div style={{ height: '1rem' }} />
      <Button
        disableElevation
        endIcon={<AddShoppingCartIcon />}
        color="secondary"
        variant="contained"
        size="small"
        sx={{
          borderRadius: '50px',
          width: '300px',
        }}
        onClick={breakGrill}
      >
        <span style={{ marginTop: '.15rem' }}>
        Set Broken
        </span>
      </Button>
      <div style={{ height: '1rem' }} />
      <Button
        disableElevation
        endIcon={<AddShoppingCartIcon />}
        color="secondary"
        variant="outlined"
        size="small"
        sx={{
          borderRadius: '50px',
          width: '300px',
        }}
        onClick={repairGrill}
      >
        <span style={{ marginTop: '.15rem' }}>
        Set Repaired
        </span>
      </Button>
      <div style={{ height: '1rem' }} />
      <Button
        disableElevation
        endIcon={<AddShoppingCartIcon />}
        color="secondary"
        variant="outlined"
        size="small"
        sx={{
          borderRadius: '50px',
          width: '300px',
        }}
        onClick={destroyDB}
      >
        <span style={{ marginTop: '.15rem' }}>
        Destroy DB
        </span>
      </Button>
    </div>
  );
};
export default AdminPage;
