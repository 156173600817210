import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { orderHistory } from '../../constants';

export const OrderDetailPage = (props: any) => {

  const params = useParams();

  const theme = useTheme();

  const [orderDetails, setOrderDetails] = useState<any>({});

  useEffect(() => {
    const orderObject = orderHistory.find(({id}) => id == params.id);
    orderObject ? setOrderDetails(orderObject) : setOrderDetails({});
  },[]);

  return (
    <div style={{
        height: 'calc(100vh - 3rem)',
        background: theme.palette.grey[900],
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '1rem 1rem 0 0',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      {Object.keys(orderDetails).map((key: any, idx: any) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              borderTop: `1px solid #444444`
            }}
            key={key + idx}
          >
            <div
              style={{
                flex: 1,
                maxWidth: '11rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                fontWeight: 'bold',
                padding: '.5rem .5rem .5rem 1rem',
                textTransform: 'capitalize'
              }}
            >
              {key}
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                textAlign: 'right',
                padding: '.5rem 1rem .5rem .5rem'
              }}
            >
              {orderDetails[key]}
            </div>
          </div>
        );
      })}
    </div>
  );

};

export default OrderDetailPage;
